.main {
  background: url("#") center center no-repeat;
  height: auto;
  overflow-x: hidden;
  max-width: 900px;
  margin: 0 auto;
  padding: 20px;
  font-family: "Montserrat", sans-serif;
}

